<template>
    <b-card>
        <Form
            ref="createPost"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions } from "vuex";

export default {
    name: "CreatePost",

    components: {
        Form
    },

    methods: {
        ...mapActions({
            createPost: 'post/create',
        }),
        submit() {
            const refPost = this.$refs.createPost
            refPost.loading = true
            refPost.errMessage = null

            const data = JSON.parse(JSON.stringify(refPost.post))

            data.genres = data.genres.map(genre => parseInt(genre.value))
            data.actors = data.actors.map(actor => parseInt(actor.value))
            data.categories = data.categories.map(category => parseInt(category.value))
            data.meta_keywords = data.meta_keywords.toString()
            data.compatible = data.compatible.toString()
            this.createPost(data).then(() => {
                this.$router.push({ name: 'posts' })
                this.$swal({
                    icon: 'success',
                    title: this.$t(`Created!`),
                    text: this.$t('Your file has been created.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

            }).catch(response => {
                refPost.loading = false
                if (response.errors) {
                    refPost.$refs.form.setErrors(response.errors)
                }
                refPost.errMessage = response.message
            })
        }
    }
}
</script>
